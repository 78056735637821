import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAsyncRetry } from 'react-use';
import Mark from 'mark.js';
import { Box, Button, Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { getRelativeTime } from 'utils/Utils';
import FinancialResult, { ja_en as ResultItemLabels } from 'model/FinancialResult';
import TanshinService from 'services/TanshinService';
import AsyncPresenter from 'components/pages/Article/Presenter';
import CategoryChips from 'components/ui/CategoryChips';
import CompanyIcon from 'components/ui/CompanyIcon';
import Link from 'components/ui/Link';

const GrowthRate = ({ value }: { value?: number }) => {
  if (value === undefined) {
    return <>-</>;
  } else if (value > 0) {
    return (
      <Typography fontWeight="bold" color="red">
        {`+${value}%`}
      </Typography>
    );
  } else {
    return (
      <Typography fontWeight="bold" color="red">
        {`${value}%`}
      </Typography>
    );
  }
};

type ContentProps = {
  value: FinancialResult;
};

const Content = ({ value }: ContentProps) => {

  const {
    title,
    source,
    publishedAt,
    content,
  } = value;

  const location = useLocation();

  const contentRef = useRef<HTMLElement>(null);

  const [searchValue, setSearchValue] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [marks, setMarks] = useState<NodeListOf<HTMLElement>>();


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setSearchValue(searchParams.get('keyword') || '');
  }, [location]);

  useEffect(() => {
    if (!marks) {
      return;
    }

    const markList = Array.from(marks);
    markList.forEach(v => {
      v.classList.remove('current');
    });

    const currentMark = marks[currentIndex];
    if (!currentMark) {
      return;
    }

    currentMark.classList.add('current');
  }, [marks, currentIndex]);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    const mark = new Mark(contentRef.current);
    mark.unmark({
      done: () => {
        mark.mark(searchValue, {
          done: () => {
            setMarks(contentRef.current?.querySelectorAll('mark'));
            setCurrentIndex(0);
          }
        });
      }
    });

  }, [searchValue]);

  return (
    <Paper sx={{ p: 2 }} square>
      <Stack direction="row">
        <Box p={1}>
          <CompanyIcon
            alt={source.company.name}
            src={source.company.thumbnailUrl?.toString()}
            sx={{
              width: 48,
              height: 48
            }}
          />
        </Box>
        <Box p={1} flexGrow={1}>
          <Stack direction="row">
            <CategoryChips value={value} />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} width="1">
              <span>{getRelativeTime(publishedAt, 'LL')}</span>
            </Stack>
          </Stack>
          <Link to={`/company/${source.company.secCode}`} underline="hover" onClick={e => e.stopPropagation()}>
            {source.company.name}
          </Link>
        </Box>
      </Stack>

      <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold' }}>{title}</Typography>

      <Box sx={{ m: '0 auto', mt: 2 }}>
        {content && <>
          <Box sx={{ m: '0 auto', mt: 2 }}>
            <Typography component="h1" variant="h6" fontWeight="bold" >四半期業績</Typography>
            <Divider />
            <TableContainer>
              <Table>
                <TableBody>
                  {Object.keys(content).filter(key => !key.endsWith('GrowthRate')).map(key => (
                    <TableRow
                      key={key}
                    >
                      <TableCell component="th" scope="row">
                        <Typography component="h1" variant="h6" fontWeight="bold" color="green">
                          {Object.entries(ResultItemLabels).find((v) => v[1] === key)?.[0]}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h5" fontWeight="bold" sx={{ display: 'inline-block' }}>
                          {content[key]?.current?.toLocaleString()}
                        </Typography>
                        <Typography sx={{ display: 'inline-block' }} >&nbsp;百万円</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <GrowthRate value={content[`${key}GrowthRate`]?.current} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ m: '0 auto', mt: 4 }}>
            <Typography component="h1" variant="h6" fontWeight="bold" >通期業績</Typography>
            <Divider />
            <TableContainer>
              <Table>
                <TableBody>
                  {Object.keys(content).filter(key => !key.endsWith('GrowthRate')).map(key => (
                    <TableRow
                      key={key}
                    >
                      <TableCell component="th" scope="row">
                        <Typography component="h1" variant="h6" fontWeight="bold" color="green">
                          {Object.entries(ResultItemLabels).find((v) => v[1] === key)?.[0]}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h5" fontWeight="bold" sx={{ display: 'inline-block' }}>
                          {content[key]?.quarterly?.toLocaleString()}
                        </Typography>
                        <Typography sx={{ display: 'inline-block' }} >&nbsp;百万円</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <GrowthRate value={content[`${key}GrowthRate`]?.quarterly} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
        }
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1.5
          }}
        >
          <Button variant="outlined" href={source.url || ''} target="_blank" >PDFを開く</Button>
        </Box>
      </Box>
    </Paper >
  );
};

const FinancialResultArticle = ({ docId }: { docId: string }) => {
  const fetchState = useAsyncRetry(() => TanshinService.fetch(docId), []);

  return (
    <AsyncPresenter loading={fetchState.loading}>
      {fetchState.value
        ? <Content value={fetchState.value as FinancialResult} />
        : null}
    </AsyncPresenter>
  );
};

export default FinancialResultArticle;