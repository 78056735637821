import { Box, Divider, Paper, Stack, TextField, ToggleButton, Typography } from '@mui/material';
import CompanyIcon from 'components/ui/CompanyIcon';
import Container from 'components/ui/Container';
import Link from 'components/ui/Link';
import { useSearch } from 'hooks/useSearch';
import Company from 'model/Company';
import { useState } from 'react';
import { User, useUser } from '../../context/UserProvider';

type FollowableCompany = Company & {
  isFollowed: boolean;
};

const CompanyCard = ({ value }: { value: FollowableCompany }) => {
  const { secCode, name, thumbnailUrl, isFollowed: iniIsFollowed } = value;
  const user = useUser() as User;
  const [isFollowed, setIsFollowed] = useState<boolean>(iniIsFollowed);

  const handleOnToggle = () => {
    const toState = !isFollowed;
    setIsFollowed(toState);
    user.setFollowedAt(secCode, toState);
  };

  return (
    <Stack
      key={secCode}
      direction="row"
    >
      <Box mr={2}>
        <CompanyIcon
          alt={name}
          src={thumbnailUrl?.toString()}
          sx={{
            width: 48,
            height: 48
          }}
        />
      </Box>
      <Box flexGrow={1}>
        <Typography variant="body2" component="h1" sx={{ mb: 1 }}>
          {secCode}
        </Typography>
        <Typography variant="body1" fontWeight="bold" component="h2" >
          <Link to={`/company/${secCode}`} underline="hover" onClick={e => e.stopPropagation()} display="inline-block" mt={0.5}>
            {name}
          </Link>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FollowButton isFollowed={isFollowed} onClick={handleOnToggle} />
      </Box>
    </Stack>
  );
};


type FollowButtonProps = {
  isFollowed: boolean;
  onClick?: React.FormEventHandler<HTMLButtonElement>;
};

const FollowButton = ({ isFollowed, onClick }: FollowButtonProps) => {
  return (
    <ToggleButton
      size="small"
      value="check"
      selected={isFollowed}
      onChange={onClick}
      sx={{ width: 104 }}
    >
      {isFollowed ? 'フォロー中' : 'フォロー'}
    </ToggleButton>
  );
};

const SearchContainer = () => {

  const { search } = useSearch();
  const user = useUser() as User;
  const [companyList, setCompanyList] = useState<FollowableCompany[]>([]);

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const result = search(e.currentTarget.value);
    const companyList = result.filter(v => v.data).map(v => v.data) as Company[];

    const unFollows = companyList.map(v => {
      const clone = Object.assign(v, {
        isFollowed: user.isFollowedAt(v.secCode)
      });

      return clone;
    }).slice(0, 20);
    setCompanyList(unFollows);
  };


  return (
    <Container>
      <Typography variant="h4" color="inherit" component="h1" sx={{ m: 2 }}>
        フォローする企業を探す
      </Typography>
      <Paper sx={{ minHeight: 800, p: 2 }} square>
        <TextField
          fullWidth
          type="search"
          placeholder="銘柄コード、銘柄名で検索できます"
          onChange={handleOnChange}
          autoFocus
          sx={{
            mb: 2
          }}
        />
        <Stack
          spacing={1}
          divider={<Divider />}
        >
          {companyList.map(v => <CompanyCard key={v.secCode} value={v} />)}
        </Stack>
      </Paper>
    </Container>
  );
};

export default SearchContainer;