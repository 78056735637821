import { ArticleSource, ArticleSubject, ArticleTypes } from 'model/IArticle';
import IDisclosure from 'model/IDisclosure';

/**
 * 有価証券報告書記事
 */
export default class Securities implements IDisclosure {
  id;
  type: ArticleTypes = 'securities';
  publishedAt;
  url;
  subject;
  source;
  title;
  content;
  description?: string;

  increaseKeywords: string[];
  decreaseKeywords: string[];

  constructor(
    id: string,
    publishedAt: string,
    url: string,
    subject: ArticleSubject,
    source: ArticleSource,
    title: string,
    content: string,
    increaseKeywords: string[],
    decreaseKeywords: string[],
    description?: string
  ) {
    this.id = id;
    this.publishedAt = publishedAt;
    this.url = url;
    this.subject = subject;
    this.source = source;
    this.title = title;
    this.content = content;
    this.description = description ?? content;
    this.increaseKeywords = increaseKeywords;
    this.decreaseKeywords = decreaseKeywords;
    this.type = 'securities';
  }
}
