export default class Company {
  secCode: string;
  name: string;
  nameEn?: string;
  nameShort?: string;
  thumbnailUrl?: string;

  constructor(
    secCode: string,
    name: string,
    nameEn?: string,
    nameShort?: string,
    thumbnailUrl?: string
  ) {
    this.secCode = secCode;
    this.name = name;
    this.nameEn = nameEn;
    this.nameShort = nameShort;
    this.thumbnailUrl = thumbnailUrl;
  }
}
