import React from 'react';
import { Container as MuiContainer, ContainerProps, styled } from '@mui/material';

const StyledContainer = styled(MuiContainer)<ContainerProps>(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  }
}));

const Container = React.forwardRef<any, ContainerProps>(({ children, maxWidth, ...rest }) => (
  <StyledContainer
    {...rest}
    maxWidth={maxWidth || 'md'}
  >
    {children}
  </StyledContainer>
));

export default Container;