import React from 'react';
import { Business } from '@mui/icons-material';
import { Avatar, AvatarProps } from '@mui/material';

const CompanyIcon = React.forwardRef<any, AvatarProps>((props, ref) => (
  <Avatar
    {...props}
    ref={ref}
  >
    <Business
      sx={{
        minWidth: '70%',
        minHeight: '70%'
      }}
    />
  </Avatar>
));

export default CompanyIcon;