import { BoxProps, styled, Box } from '@mui/material';

const Logo = styled(Box)<BoxProps>(() => ({
  width: 268,
  height: 90,
  backgroundImage: `url(${process.env.PUBLIC_URL + '/logo.svg'})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat'
}));

export default Logo;