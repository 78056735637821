import { FormEventHandler, ReactNode, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as firebaseAuth from "firebase/auth";
import { GoogleAuthProvider } from 'firebase/auth';
import { Google as GoogleIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import Link from 'components/ui/Link';

// TODO: singupと共通化
const LoginForm = ({ headerLabel }: { headerLabel?: ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    if (!email || !password) {
      return;
    }

    (async () => {
      try {
        await firebaseAuth.signInWithEmailAndPassword(
          firebaseAuth.getAuth(),
          email.toString(),
          password.toString()
        );

        const redirectTo = query.get('to');
        if (redirectTo) {
          navigate(redirectTo, { replace: true });
        }
      } catch (e) {
        window.alert('メールアドレスまたはパスワードが間違っています。');
      }
    })()
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = firebaseAuth.getAuth();

    (async () => {
      try {
        await firebaseAuth.signInWithRedirect(auth, provider);
        const redirectTo = query.get('to');
        if (redirectTo) {
          navigate(redirectTo, { replace: true });
        }

      } catch (e) {
        console.log(e);
      }
    })();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
      }}
    >
      {headerLabel}
      {/* <Typography component="h1" variant="h5">
        ログイン
      </Typography> */}
      <Box
        component="form"
        sx={{
          width: '100%'
        }}
        onSubmit={handleSubmit}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="メールアドレス"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <Box mt={2} />
        <FormControl variant="outlined" required fullWidth>
          <InputLabel htmlFor="password" required>パスワード</InputLabel>
          <OutlinedInput
            id="password"
            name='password'
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            required
            label="パスワード 　"
            fullWidth
          />
        </FormControl>
        <Grid container alignItems="center">
          <Grid item xs>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="ログイン状態を保持"
            />

          </Grid>
          <Grid item>
            <Link to="/reminder" variant="body2" >
              パスワードをお忘れですか？
            </Link>
          </Grid>
        </Grid>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          ログイン
        </Button>
        <Divider>または</Divider>
        <Box mt={2} />

        <Button
          fullWidth
          variant="outlined"
          startIcon={
            <GoogleIcon />
          }
          sx={{
            color: (theme) => theme.palette.text.primary
          }}
          onClick={handleClickGoogle}
        >
          Google でログイン
        </Button>

      </Box>
    </Box >
  );
};

export default LoginForm;