import { Paper, Stack } from '@mui/material';
import { useUser } from 'components/context/UserProvider';
import Container from 'components/ui/Container';
import TimeLineCard from 'components/containers/TimeLineCard';
import { TimeLineCardValue } from 'components/ui/TimeLineCard';

const Bookmarks = () => {

  const user = useUser();

  return (
    <Container>
      <Paper sx={{ flexGrow: 1, p: 2 }} square>
        <Stack spacing={1}>
          {
            // FIXME: bookmarksの構造未設計
            user?.bookmarks.map(v => <TimeLineCard hideBody key={v.id} value={v as TimeLineCardValue} />)
          }
        </Stack>
      </Paper>
    </Container>
  );
};

export default Bookmarks;