import { useAsyncRetry } from 'react-use';
import { Box, Button, GlobalStyles, Paper, Stack, Typography } from '@mui/material';
import { getRelativeTime } from 'utils/Utils';
import OtherDisclosure from 'model/Other';
import TanshinService from 'services/TanshinService';
import YuuhouService from 'services/YuuhouService';
import AsyncPresenter from 'components/pages/Article/Presenter';
import CategoryChips from 'components/ui/CategoryChips';
import CompanyIcon from 'components/ui/CompanyIcon';
import Link from 'components/ui/Link';

const globalStyle = <GlobalStyles
  styles={{
    'mark.current': {
      backgroundColor: '#fbc02d' //yellow[700]
    }
  }}
/>;

type ContentProps = {
  value: OtherDisclosure;
};

const Content = ({ value }: ContentProps) => {
  const {
    title,
    source,
    publishedAt,
    pdfUrl
  } = value;

  return (
    <Paper sx={{ p: 2 }} square>
      {globalStyle}

      <Stack direction="row">
        <Box p={1}>
          <CompanyIcon
            alt={source.company.name}
            src={source.company.thumbnailUrl?.toString()}
            sx={{
              width: 48,
              height: 48
            }}
          />
        </Box>
        <Box p={1} flexGrow={1}>
          <Stack direction="row">
            <CategoryChips value={value} />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} width="1">
              <span>{getRelativeTime(publishedAt, 'LL')}</span>
            </Stack>
          </Stack>
          <Link to={`/company/${source.company.secCode}`} underline="hover" onClick={e => e.stopPropagation()}>
            {source.company.name}
          </Link>
        </Box>
      </Stack>

      <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold' }}>{title}</Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1.5
        }}
      >
        <Button variant="outlined" href={pdfUrl || ''} target="_blank" >PDFを開く</Button>
      </Box>

    </Paper>
  );
};


const OtherDisclosureArticle = ({ docId }: { docId: string }) => {
  const fetchState = useAsyncRetry(async () => {
    switch (docId.substring(0, 1)) {
      case 'y':
        return await YuuhouService.fetch(docId);
      case 't':
        return await TanshinService.fetch(docId);
      default:
        return null
    }

  }, []);

  return (
    <AsyncPresenter loading={fetchState.loading}>
      {fetchState.value
        ? <Content value={fetchState.value as OtherDisclosure} />
        : null}
    </AsyncPresenter>
  );
};

export default OtherDisclosureArticle;