import dayjs from 'dayjs';
import IArticle from 'model/IArticle';
import PublicArticleService from 'services/PublicArticleService';
import { DisclosureService } from 'services/DisclosureService';

/**
 * タイムライン
 */
export const TimeLineService = {

  fetchAll: async (secCodes: string[]): Promise<IArticle[]> => {
    const disclosureTask = DisclosureService.fetchAll(secCodes);
    const publicArticleTask = secCodes.map(v => PublicArticleService.fetchAll(v));

    const disclosures = await disclosureTask;
    const publicArticleTaskResponse = await Promise.all(publicArticleTask);
    const publicArticles = publicArticleTaskResponse.flatMap(v => v).flatMap(v => v);
    const articles = [...disclosures, ...publicArticles];

    return articles.sort((a, b) => dayjs(b.publishedAt).diff(dayjs(a.publishedAt)));
  }
};

export default TimeLineService;