import React from 'react';
import { Tab, TabProps } from '@mui/material';
import { useHref, useLinkClickHandler } from 'react-router-dom';

type LinkTabProps = Omit<TabProps<'a'>, 'href'> & {
  to: string;
  replace?: boolean;
  state?: any;
};

const LinkTab = React.forwardRef<any, LinkTabProps>((props, ref) => {
  const {
    to,
    target,
    replace,
    state,
    onClick,
    ...rest
  } = props;

  // FIXME: 外部リンクの考慮漏れ
  const href = useHref(to);
  const innerClick = useLinkClickHandler(to, {
    replace,
    state,
    target
  });

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    onClick?.(e);

    if (!e.defaultPrevented) {
      innerClick(e);
    }
  };

  return (
    <Tab
      {...rest}
      ref={ref}
      component="a"
      onClick={handleClick}
      href={href}
    />
  );
});

export default LinkTab;
