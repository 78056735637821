import dayjs from 'dayjs';
import IDisclosure from 'model/IDisclosure';
import YuuhouService from 'services/YuuhouService';
import TanshinService from 'services/TanshinService';

/**
 * 適時開示情報
 */
export const DisclosureService = {

  fetchAll: async (secCodes: string[]): Promise<IDisclosure[]> => {
    const securitiesTasks = secCodes.map(v => YuuhouService.fetchAll(v));
    const resultTasks = secCodes.map(v => TanshinService.fetchAll(v));

    const tasks = await Promise.all([...securitiesTasks, ...resultTasks]) as IDisclosure[][];
    const articles = tasks.flatMap(v => v).flatMap(v => v);

    return articles.sort((a, b) => dayjs(b.publishedAt).diff(dayjs(a.publishedAt)));
  }
}
