// 実行時環境変数
globalThis.runtime = {
  env: {
    API_URL: (() => {
      const map = {
        'app.hooolders.com': 'https://api.hooolders.com',
        'kavote-dev.firebaseapp.com': 'https://kavote-dev-api.firebaseapp.com',
        'kavote-dev.web.app': 'https://kavote-dev-api.firebaseapp.com',
        'dev-app.hooolders.com': 'https://dev-api.hooolders.com'
      };

      const url = map[location.hostname];
      return process.env.REACT_APP_API_URL || url || 'https://kavote-dev-api.firebaseapp.com';
    })()
  }
};
