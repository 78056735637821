import SvgIcon from 'components/icons/SvgIcon';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { SvgIconProps } from '@mui/material';

export const ExternalLink
  = (props: SvgIconProps) =>
    <SvgIcon
      {...props}
      icon={faExternalLink}
      sx={{
        p: '3px',
        verticalAlign: 'middle',
        ...props.sx
      }}
    />;