import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createGlobalState, useAsync, useEffectOnce, useLocation } from 'react-use';
import TimeLineService from 'services/TimeLineService';
import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Stack, Tabs, Typography } from '@mui/material';
import { User, useUser } from 'components/context/UserProvider';
import Landing from 'components/pages/Home/Landing';
import Container from 'components/ui/Container';
import TimeLineCard, { TimeLineCardValue } from 'components/containers/TimeLineCard';
import ProgressBox from 'components/ui/ProgressBox';
import dayjs from 'dayjs';
import LinkTab from 'components/ui/LinkTab';

const ShouldFollow = () => {
  const navigate = useNavigate();

  const goToSearch = () => navigate('/search');
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" component="h1" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>企業をフォローする</Typography>
      <Typography variant="body1" component="p" align="center" gutterBottom>あなたのお気に入りの企業をフォローしましょう</Typography>
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          disableElevation
          startIcon={<AddIcon />}
          onClick={goToSearch}
        >
          新規追加
        </Button>
      </Box>
    </Box>
  );
};

const useTimelineCache = createGlobalState<{
  value: TimeLineCardValue[],
  meta?: {
    // タイムラインを更新する契機となるメタデータ
    follows?: string[],
    timestamp?: number
  }
} | null | undefined>();

export const UsersHome = ({ value: user }: { value: User }) => {
  const location = useLocation();

  const [timeLineCache, setTimeLineCache] = useTimelineCache();
  const hasFollows = user.follows && user.follows.length > 0;
  const selectedTab = location.hash?.substring(1) || 'all';

  // フォロワーを変更した場合に情報の再取得を行う
  useEffectOnce(() => {
    if (!timeLineCache?.meta?.follows) {
      return;
    }

    // 初回レンダリング時にキャッシュとユーザー情報を比較し、
    // 差分がある場合は再ローディングするためにキャッシュをクリアする
    // TODO: 分かりづらいので null セットではなく、refresh関数を呼び出す方式にする
    //       フォロワー数は多くても100件程度を想定
    const currentFollows = [...user.follows].sort()
    const cacheFollows = [...timeLineCache.meta.follows].sort();

    if (currentFollows.length !== cacheFollows.length
      || currentFollows.some((v, i) => v !== cacheFollows[i])) {
      setTimeLineCache(null);
    }
  });

  useAsync(async () => {
    if (!hasFollows || timeLineCache) {
      return;
    }

    try {
      const res = await TimeLineService.fetchAll(user.follows);

      let timeLine = res.filter(history => !user.isVisitedAt(history)); // 初回表示時、すでに訪問済みは非表示

      setTimeLineCache({
        value: timeLine,
        meta: {
          follows: user.follows,
          timestamp: dayjs().unix()
        }
      });

    } catch (e) {
      console.log(e);
    }
  }, [timeLineCache]);

  const timeLineFilter = useCallback((value: TimeLineCardValue): boolean => {
    switch (selectedTab) {
      case 'securities':
      case 'result':
        return value.type === selectedTab;
      case 'other':
        return value.type === 'other-disclose';
      case 'nikkei':
        return value.type === 'public' && value.source.type === 'nikkei';
      case 'pressrelease':
        return value.type === 'public' && value.source.type === 'pressrelease';
      case 'all':
      default:
        return true;
    }
  }, [selectedTab]);

  const render = (value: TimeLineCardValue[]) => {
    const filtered = value.filter(timeLineFilter).slice(0, 60);
    return (
      <>
        {filtered.length > 0
          ? <Stack spacing={1}>
            {value.filter(timeLineFilter).slice(0, 60).map((v) =>
              <TimeLineCard
                key={v.id}
                value={v}
              />
            )}
          </Stack>
          : <Typography variant="h5" component="h1" align="center" sx={{ mt: 2, fontWeight: 'bold' }}>新着記事はありません</Typography>
        }
      </>
    );
  };

  return (
    <>
      <Box sx={{
        borderBottom: 1,
        backgroundColor: (theme) => theme.palette.background.default,
        borderColor: 'divider'
      }}
      >
        <Container>
          <Tabs
            value={selectedTab}
            variant="scrollable"
            scrollButtons="auto"
          >
            <LinkTab label="すべて" value="all" to="/#all" replace />
            <LinkTab label="有価証券報告書" value="securities" to="/#securities" replace />
            <LinkTab label="決算短信" value="result" to="/#result" replace />
            <LinkTab label="その他IR" value="other" to="/#other" replace />
            <LinkTab label="日経ニュース" value="nikkei" to="/#nikkei" replace />
            <LinkTab label="プレスリリース" value="pressrelease" to="/#pressrelease" replace />
          </Tabs>
        </Container>
      </Box>

      <Container sx={{ flexGrow: 1 }}>
        {hasFollows
          ? timeLineCache
            ? timeLineCache.value.length > 0
              ? render(timeLineCache.value)
              : <Typography variant="h5" component="h1" align="center" sx={{ mt: 2, fontWeight: 'bold' }}>新着記事はありません</Typography>
            : <ProgressBox />
          : <ShouldFollow />
        }
      </Container >
    </>
  );
};

const Home = () => {
  const user = useUser();

  return (
    <>
      {user
        ? <UsersHome value={user} />
        : <Landing />
      }
    </>
  );
};

export default Home;
