import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { InitOptions } from 'react-ga4/types/ga4';

let doesInitialized = false;

const useTracker = (args?: string | InitOptions[]) => {
  if (!doesInitialized && args != null) {
    doesInitialized = true;
    ReactGA.initialize(args); 
  }

  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.href.substring(window.location.origin.length) });
  }, [location]);
};

export default useTracker;
