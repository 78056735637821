import { useCallback, useMemo } from 'react';
import { useAsync } from 'react-use';
import Lunr from 'lunr';
import CompanyService from 'services/CompanyService';

type Ilunr = (config: Lunr.ConfigFunction) => Lunr.Index
type JPlunr = Ilunr & {
  ja: any,
  multiLanguage: any
};
const lunr: JPlunr = require('lunr');
require('lunr-languages/lunr.stemmer.support.js')(lunr);
require('lunr-languages/tinyseg.js')(lunr);
require('lunr-languages/lunr.ja.js')(lunr);
require('lunr-languages/lunr.multi.js')(lunr);

export const useSearch = () => {
  const { value } = useAsync(() => CompanyService.fetchAll(), []);
  const idx = useMemo(() => {
    if (!value) {
      return;
    }

    return lunr(function () {
      const lunrConfig = this;

      lunrConfig.use(lunr.multiLanguage('en', 'ja'));
      lunrConfig.ref('secCode');
      lunrConfig.field('secCode');
      lunrConfig.field('name');
      lunrConfig.field('nameEn');
      lunrConfig.field('nameShort');

      value.forEach(v => lunrConfig.add(v));
    });
  }, [value]);

  const search = useCallback((query: string) => {
    if (!query || !idx) {
      return [];
    }

    const lunrResult = idx.search(`*${query}*`);

    const result = lunrResult.map(v => {
      const company = value?.find(x => x.secCode === v.ref)
      return {
        ...v,
        data: company
      };
    });

    return result;
  }, [idx, value]);

  return {
    search
  };
};