import { useState } from 'react';
import { Box, Grid, Typography, Container} from '@mui/material';
import LoginForm from 'components/pages/Login/LoginForm/LoginForm';
import SingupForm from 'components/pages/Login/SingupForm/SingupForm';
import Link from 'components/ui/Link';

const Landing = () => {
  const [hasAccount, setHasAccount] = useState<boolean>(false);

  const handleToggleState = () => {
    setHasAccount((prev) => !prev);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'white'
      }}

    >
      <Container maxWidth="md" sx={{ pt: 4 }}>
        <Typography variant="h3" component="h1" fontWeight="bold">
          <Typography fontFamily="JosefinSans-Bold, Josefin Sans" component="span" variant='inherit' color={(theme) => theme.palette.primary.main}>Hooolders</Typography>
           {" を始めよう。"}
        </Typography>
        <Box sx={{
          maxWidth: 400,
          width: '100%',
          mt: 2
        }}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="body1">
                {!hasAccount
                  ? 'フリーアカウントを作成'
                  : 'ログイン'
                }
              </Typography>
            </Grid>
            <Grid item>
              または
              <Link
                component="button"
                variant="body2"
                underline="hover"
                onClick={handleToggleState}
              >
                {hasAccount
                  ? '新規登録'
                  : 'ログイン'
                }
              </Link>
            </Grid>
          </Grid>
          {hasAccount
            ? <LoginForm />
            : <SingupForm />
          }
        </Box>
        <Box mt={2} />

      </Container>

    </Box>

  );
};

export default Landing;