
import axios from 'axios';
import Company from 'model/Company';
import IArticle from 'model/IArticle';
import CompanyService from 'services/CompanyService';

const toArticle = (value: any): IArticle => ({
  id: value['_id'],
  type: 'public',
  publishedAt: value['published_at'],
  url: `/article/public/${value['_id']}`,
  subject: { secCode: value['code'] },
  source: {
    type: value['src_type'] === 'news_prtimes' ? 'pressrelease' : 'nikkei',
    url: value['src_url'],
    company: CompanyService.get(value['code'] as string) as Company,
  },
  title: value['title'],
});

/**
 * 外部記事
 */
const PublicArticleService = {

  fetchAll: async (secCode?: string): Promise<IArticle[]> => {
    const response = await axios.get(`${runtime.env.API_URL}/api/article/list/${secCode}`);
    return response.data.data.map((v: any): IArticle => toArticle(v));
  },

  fetch: async (docId: string): Promise<IArticle> => {
    const response = await axios.get(`${runtime.env.API_URL}/api/article/${docId}`);
    return toArticle(response.data.data);
  }
};

export default PublicArticleService;