import { NotFound } from 'components/pages';
import OtherDiscloseArticle from 'components/pages/Article/OtherDiscloseArticle';
import PublicArticle from 'components/pages/Article/PublicArticle';
import SecuritiesArticle from 'components/pages/Article/SecuritiesArticle';
import Container from 'components/ui/Container';
import { useParams } from 'react-router-dom';
import FinancialResultArticle from './FinancialResultArticle';

const Article = () => {
  const { type = '', id = '' } = useParams();

  const render = () => {
    switch (type) {
      case 'securities':
        return <SecuritiesArticle docId={id} />;
      case 'result':
        return <FinancialResultArticle docId={id} />;
      case 'other-disclose':
        return <OtherDiscloseArticle docId={id} />;
      case 'public':
        return <PublicArticle docId={id} />
      default:
        return <NotFound />
    }
  };

  return (
    <Container maxWidth="md" >
      {render()}
    </Container>
  );
};

export default Article;