import { ArticleSource, ArticleSubject, ArticleTypes } from 'model/IArticle';
import IDisclosure from 'model/IDisclosure';

export const ja_en: Record<string, string> = {
  '売上': 'netSales',
  '売上成長率': 'netSalesGrowthRate',
  '純利益': 'profit',
  '純利益成長率': 'profitGrowthRate',
  '営業利益': 'operatingRevenue',
  '営業利益成長率': 'operatingRevenueGrowthRate',
  '経常利益': 'ordinaryProfit',
  '経常利益成長率': 'ordinaryProfitGrowthRate',
  '当期連結': 'current',
  '年度連結': 'quarterly',
};

export interface FinancialResultItem {
  [key: string]: {
    current?: number,
    quarterly?: number
  }
}

/**
 * 決算短信記事
 */
export default class FinancialResult implements IDisclosure {
  id;
  type: ArticleTypes = 'result';
  publishedAt;
  url;
  subject;
  source;
  title;
  content;

  constructor(
    id: string,
    publishedAt: string,
    url: string,
    subject: ArticleSubject,
    source: ArticleSource,
    title: string,
    content?: FinancialResultItem
  ) {
    this.id = id;
    this.publishedAt = publishedAt;
    this.url = url;
    this.subject = subject;
    this.source = source;
    this.title = title;
    this.content = content;
  }
}