import { UserInfo } from 'firebase/auth';
import User from 'model/User';

/**
 * タイムライン
 */
export const UserService = {

  fetch: async (user: UserInfo): Promise<User> => {
    // TODO: DB対応

    const usersJson = localStorage.getItem('users') ?? '[]';
    const users = JSON.parse(usersJson);
    let userInfo = users.find((v: any) => v['uid'] === user.uid);

    if (!userInfo) {
      userInfo = {
        uid: user.uid,
        follows: [],
        bookmarks: [],
        visitedHistory: []
      };

      users.push(userInfo);
      localStorage.setItem('users', JSON.stringify(users));
    } else {
      if (userInfo['_v'] === 1) {
        userInfo = {
          ...userInfo,
          follows: userInfo.follows ?? [],
          bookmarks: userInfo.bookmarks ?? [],
          visitedHistory: userInfo.visitedHistory ?? []
        };
      } else {
        userInfo = {
          ...userInfo,
          _v: 1,
          follows: [],
          bookmarks: [],
          visitedHistory: []
        };
      }

      UserService.update(userInfo);
    }

    return {
      ...user,
      ...userInfo
    };
  },

  update: async (user: User): Promise<void> => {
    const usersJson = localStorage.getItem('users') ?? '[]';
    const users = JSON.parse(usersJson);

    const newValue = users.map((v: User) => {
      if (v.uid !== user.uid) {
        return v;
      }

      return user;
    });
    localStorage.setItem('users', JSON.stringify(newValue));
  },
};

export default UserService;