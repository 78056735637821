import { Box, Link, Paper, Typography } from '@mui/material';
import { useUser } from 'components/context/UserProvider';
import LoginForm from 'components/pages/Login/LoginForm/LoginForm'
import SingupForm from 'components/pages/Login/SingupForm/SingupForm';
import Container from 'components/ui/Container';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

const Login = () => {
  const user = useUser();
  const [hasAccount, setHasAccount] = useState<boolean>(true);

  const handleToggleState = () => {
    setHasAccount((prev) => !prev);
  };

  return (
    <>
      {user
        ? <Navigate to="/" replace />
        : <Container sx={{ pt: 4 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Paper sx={{
              maxWidth: 500,
              width: '100%',
              p: 4
            }}>
              <Typography variant="h4" component="h1" align="center">
                {hasAccount
                  ? 'ログイン'
                  : '新規登録'
                }
              </Typography>
              {hasAccount
                ? <LoginForm />
                : <SingupForm />
              }
            </Paper>
            <Box mt={2} />
            <Link
              component="button"
              variant="body2"
              underline="hover"
              onClick={handleToggleState}
            >
              {hasAccount
                ? '新規登録はこちら'
                : 'アカウントをお持ちですか？'
              }
            </Link>
          </Box>
        </Container>
      }
    </>
  );
};

export default Login;