import dayjs, { Dayjs } from 'dayjs';

export const getRelativeTime = (value: Dayjs | string | undefined, format = 'LL' ) => {
  if (!value) {
    return null;
  }

  const daysValue = dayjs.isDayjs(value) ? value : dayjs(value);

  if (daysValue.isValid()) {
    if (daysValue.isAfter(dayjs().add(1, 'day'))) {
      return daysValue.fromNow();
    }
    
    return daysValue.format(format);
  }

  return null;
};
