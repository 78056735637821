import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Link, Typography} from '@mui/material';
import Logo from 'components/ui/Logo';

const NotFound = () => (
  <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column'}} >
    <Button component={RouterLink} to="/" sx={{mr: 'auto', ml: 'auto'}}>
      <Logo />
    </Button>
    <Typography variant="h3" textAlign="center" sx={{display: 'inline-block'}}>
      404 Not Found.
    </Typography>
    <Typography variant="h4" textAlign="center" sx={{display: 'inline-block'}}>
      お探しのページが見つかりませんでした。
    </Typography>
    <Link component={RouterLink} to="/" variant="h5" sx={{mr: 'auto', ml: 'auto'}}>ホームに戻る</Link>
  </Box>
);

export default NotFound;