import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookmarkBorderRounded as BookmarkBorderRoundedIcon, BookmarkRounded as BookmarkRoundedIcon, NorthEast, ThumbUpAltOutlined as ThumbUpAltOutlinedIcon } from '@mui/icons-material';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, ChipProps, IconButton, Stack, StackProps, Typography } from '@mui/material';
import { getRelativeTime } from 'utils/Utils';
import IArticle from 'model/IArticle';
import CategoryChips from 'components/ui/CategoryChips';
import CompanyIcon from 'components/ui/CompanyIcon';
import { IncreaseWordChip } from 'components/ui/KeywordChip';
import Link, { LinkProps } from 'components/ui/Link';
import OtherDisclosure from 'model/Other';
import { ExternalLink as ExternalLinkIcon } from 'components/icons/index';

const KeywordChip = React.forwardRef<any, ChipProps & LinkProps>(({ label, size, ...rest }, ref) => (
  <Link
    {...rest}
    ref={ref}
    underline="none"
  >
    <IncreaseWordChip label={label} size={size} clickable />
  </Link>
));

const Row = ({ children, ...rest }: StackProps) => (
  <Stack
    {...rest}
    direction="row"
    justifyContent="center"
    alignItems="center"
  >
    {children}
  </Stack>
);

export type TimeLineCardValue = IArticle & {
  description?: string;
  increaseKeywords?: string[];
  isBookmarked?: boolean;
  isVisited?: boolean;
};

export type TimeLineCardProps = {
  value: TimeLineCardValue;
  hideAvatar?: boolean;
  hideBody?: boolean;
  onToggleBookmark?: React.MouseEventHandler<HTMLButtonElement>;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLSpanElement>;
};

const TimeLineCard = (props: TimeLineCardProps) => {
  const { value, hideAvatar = false, hideBody = false, onClick, onToggleBookmark } = props;
  const navigate = useNavigate();
  const [voteCount, setVote] = useState(0);

  const titleLinkProps = useMemo(() => {
    if (value.type === 'public') {
      return {
        href: value.source.url,
        target: '_blank'
      };
    }

    if (value.type === 'other-disclose') {
      return {
        href: (value as OtherDisclosure).pdfUrl,
        target: '_blank'
      };
    }

    return { to: value.url };
  }, [value]);

  const handleOnClickTitle: (React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLSpanElement>) = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onClick?.(e);
  };

  const handleOnClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    onClick?.(e);
    navigate(value.url);
  };

  const handleOnBookmark: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onToggleBookmark?.(e);
  };

  const handleOnLike: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    setVote((prev) => prev + 1);
  };

  return (
    <Card sx={{ width: 1 }} variant="outlined">
      <CardActionArea
        component="div"
        onClick={handleOnClick}
      >
        <CardHeader
          sx={{ pb: 0 }}
          avatar={
            hideAvatar
              ? null
              : (
                <Link to={`/company/${value.source.company.secCode}`} underline="none" onClick={e => e.stopPropagation()}>
                  <CompanyIcon
                    alt={value.source.company.name}
                    src={value.source.company.thumbnailUrl?.toString()}
                    sx={{ width: 48, height: 48 }}
                  />
                </Link>
              )
          }
          title={
            <Row>
              <CategoryChips value={value} />
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} width="1">
                <Typography variant="caption" component="span" >{getRelativeTime(value.publishedAt)}</Typography>
              </Stack>
            </Row>
          }
          subheader={
            hideAvatar
              ? null
              : (
                <Link to={`/company/${value.source.company.secCode}`} underline="hover" onClick={e => e.stopPropagation()} display="inline-block" mt={0.5}>
                  {value.source.company.name}
                </Link>
              )
          }
        />
        <CardContent sx={{ pt: 1, pb: 1, width: 1 }}>
          <Box sx={{
            width: 1,
            pl: hideAvatar ? 0 : 8
          }}>
            <Typography
              variant="h6"
              component="h1"
              sx={theme => ({
                fontWeight: 'bold',
                color: theme.palette.primary.main,
                ...(value.isVisited && {
                  color: theme.palette.action.disabled
                })
              })}
            >
              <Link
                {...titleLinkProps}
                sx={{
                  color: 'inherit'
                }}
                onClick={handleOnClickTitle}
              >
                <span>{value.title}</span>
                {(value.type === 'other-disclose' || value.type === 'public')
                  && <ExternalLinkIcon
                    sx={{
                      verticalAlign: '-4px'
                    }}
                  />}
              </Link>
            </Typography>

            {!hideBody && <>

            {value.increaseKeywords !== undefined
              ? <>
                <Typography variant="subtitle1" color="red" >新出キーワード</Typography>
                <Stack direction="row" flexWrap="wrap" width="1" >
                  {
                    value.increaseKeywords.slice(0, 10).map((v, i) => (
                      <Box key={v} sx={{ pb: 1, pr: 1 }}>
                        <KeywordChip
                          label={
                            <Row>
                              <span>{v}</span>
                              <NorthEast fontSize="small" />
                            </Row>
                          }
                          size="small"
                          to={`${value.url}?keyword=${v}`}
                          onClick={e => e.stopPropagation()}
                        />
                      </Box>
                    ))
                  }
                </Stack>
              </>
              : null}
            {value.description !== undefined
              ? <Box mt={1}>
                <Typography
                  variant="body1"
                  sx={theme => ({
                    ...(value.isVisited && {
                      color: theme.palette.action.disabled
                    })
                  })}
                >
                  {value.description.slice(0, 140)}
                  {value.description.length > 140 && <><br /><Link
                    to={value.url}
                    underline="hover"
                    sx={{
                      fontWeight: 'bold',
                      color: 'inherit'
                    }}
                  >
                    ...続きを読む
                  </Link></>}
                </Typography>
              </Box>
              : null}
              </>}
          </Box>
        </CardContent>
        <CardActions sx={{ p: 0.5 }}>
          <Box sx={{ pl: hideAvatar ? 0 : 7, width: 1 }}>
            <Stack direction="row" flexDirection="row" width="1">
              <Box flexGrow={1} />

              <IconButton
                sx={(theme) => ({
                  color: theme.palette.primary.main
                })}
                onClick={handleOnBookmark}
              >
                {value.isBookmarked
                  ? <BookmarkRoundedIcon />
                  : <BookmarkBorderRoundedIcon />
                }
              </IconButton>
              <Button
                startIcon={<ThumbUpAltOutlinedIcon />}
                onClick={handleOnLike}
              >
                {voteCount}
              </Button>
            </Stack>
          </Box>
        </CardActions>
      </CardActionArea>
    </Card >
  );
};

export default TimeLineCard;