import axios from 'axios';
import dayjs from 'dayjs';
import { default as relativeTime } from 'dayjs/plugin/relativeTime';
import { default as updateLocale } from 'dayjs/plugin/updateLocale';
import { default as localizedFormat } from 'dayjs/plugin/localizedFormat';
import { default as timeZone } from 'dayjs/plugin/timezone';
import { default as utc } from 'dayjs/plugin/utc';
import 'dayjs/locale/ja';

/* setting for dayjs*/
dayjs.locale('ja');
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat)
dayjs.extend(timeZone);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Tokyo')

/* setting for axios */
axios.interceptors.response.use((res) => {
  if (res.data.status === 'ng') {
    throw Error(res.data.message);
  }

  return res;
});

/* extends JSON */
declare global {
  interface JSON {
    tryParse: (text: string, reviver?: ((this: any, key: string, value: any) => any) | undefined) => boolean;
  }
}

JSON.tryParse = (text: string, reviver?: ((this: any, key: string, value: any) => any) | undefined) => {
  try {
    JSON.parse(text, reviver);
    return true;
  } catch (e) {
    return false;
  }
};

export { };
