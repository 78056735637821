import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Add as AddIcon } from '@mui/icons-material';
import { Box, Button, Divider, Paper, Stack, ToggleButton, Typography } from '@mui/material';
import Company from 'model/Company';
import useCompany from 'hooks/useCompany';
import { User, useUser } from 'components/context/UserProvider';
import CompanyIcon from 'components/ui/CompanyIcon';
import Container from 'components/ui/Container';
import Link from 'components/ui/Link';

const CompanyCard = ({ value }: { value: Company }) => {
  const { secCode, name, thumbnailUrl } = value;
  const user = useUser() as User;
  const [isFollowed, setIsFollowed] = useState<boolean>(true);

  const handleOnToggle = () => {
    const toState = !isFollowed;
    setIsFollowed(toState);
    user.setFollowedAt(secCode, toState);
  };

  return (
    <Stack
      key={secCode}
      direction="row"
    >
      <Box mr={2}>
        <CompanyIcon
          alt={name}
          src={thumbnailUrl?.toString()}
          sx={{
            width: 48,
            height: 48
          }}
        />
      </Box>
      <Box flexGrow={1}>
        <Typography variant="body2" component="h1" sx={{ mb: 1 }}>
          {secCode}
        </Typography>
        <Typography variant="body1" fontWeight="bold" component="h2" >
          <Link to={`/company/${secCode}`} underline="hover" onClick={e => e.stopPropagation()} display="inline-block" mt={0.5}>
            {name}
          </Link>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FollowButton isFollowed={isFollowed} onClick={handleOnToggle} />
      </Box>
    </Stack>
  );
};


type FollowButtonProps = {
  isFollowed: boolean;
  onClick?: React.FormEventHandler<HTMLButtonElement>;
};

const FollowButton = ({ isFollowed, onClick }: FollowButtonProps) => {
  return (
    <ToggleButton
      size="small"
      value="check"
      selected={isFollowed}
      onChange={onClick}
      sx={{ width: 104 }}
    >
      {isFollowed ? 'フォロー中' : 'フォロー'}
    </ToggleButton>
  );
};

const NotFollowers = () => {
  const navigate = useNavigate();

  const goToSearch = () => navigate('/search');
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" component="h1" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>フォロー中の企業がありません</Typography>

      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          disableElevation
          startIcon={<AddIcon />}
          onClick={goToSearch}
        >
          新規追加
        </Button>
      </Box>
    </Box>
  );
};


const Follows = () => {

  const user = useUser();
  const company = useCompany();
  const follows = useMemo(() => {
    return user?.follows.map(v => company.get(v)).filter(v => v) as Company[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]); // フォロー状態に変更があっても画面上残したいため依存関係に含めない

  return (
    <Container>
      <Paper sx={{ flexGrow: 1, p: 2 }} square>
        {
          follows?.length > 0
            ? <Stack
              spacing={1}
              divider={<Divider />}
            >
              {follows?.map(v => <CompanyCard key={v.secCode} value={v} />)}
            </Stack>
            : <NotFollowers />
        }
      </Paper>
    </Container>
  );
};

export default Follows;