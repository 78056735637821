import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Article, Home, NotFound, Search } from 'components/pages';
import Company from 'components/pages/Company';
import Follows from 'components/pages/Follows';
import Login from 'components/pages/Login';
import Bookmarks from 'components/pages/Bookmarks';
import AppFrame from 'AppFrame';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AppFrame />}>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="article/:type/:id" element={<Article />} />
          <Route path="search" element={<Search />} />
          <Route path="follows" element={<Follows />} />
          <Route path="bookmarks" element={<Bookmarks />} />
          <Route path="company/:secCode" element={<Company />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
