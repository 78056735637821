import { ArrowBackIosNewRounded, Menu as MenuIcon, Search as SearchIcon, ViewList as ViewListIcon, BookmarkBorderRounded as BookmarkBorderRoundedIcon } from '@mui/icons-material';
import { AppBar, Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import HideOnScroll from 'components/ui/HideOnScroll';
import LogoLink from 'components/ui/LogoLink';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../context/UserProvider';
import * as firebaseAuth from 'firebase/auth';


const drawerWidth = 240;

const UsersHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useUser();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleClickSearch = () => {
    navigate('/search');
  };

  const handleGoBack = () => {
    window.history.back();
    // navigate(-1);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const handleLogout = () => {
    (async () => {
      await firebaseAuth.signOut(firebaseAuth.getAuth());
    })();
  };


  const drawer = (
    <div>
      <Toolbar>
        <LogoLink sx={{ height: 30 }} />
      </Toolbar>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => navigate('/follows')}
        >
          <ListItemIcon>
            <ViewListIcon />
          </ListItemIcon>
          <ListItemText primary="フォロー一覧" />
        </ListItem>
        <ListItem
          button
          onClick={() => navigate('/bookmarks')}
        >
          <ListItemIcon>
            <BookmarkBorderRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="ブックマーク" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <HideOnScroll>
        <AppBar
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            backgroundColor: 'white',
            pl: {
              sm: `${drawerWidth}px`
            }
          }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <LogoLink sx={{
              display: { xs: 'block', sm: 'none' },
              height: 30
            }} />
            {location.pathname !== '/'
              && <IconButton
                edge="start"
                onClick={handleGoBack}
              >
                <ArrowBackIosNewRounded />
              </IconButton>
            }
            <IconButton sx={{ p: '10px' }} onClick={handleClickSearch}>
              <SearchIcon />
            </IconButton>
            <Box flexGrow={1} />
            {
              user
                ? <Button color="inherit" onClick={handleLogout}>ログアウト</Button>
                : <Button color="inherit" onClick={handleLogin}>ログイン</Button>
            }
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          container={document.body}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          open
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

const LandingHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = () => {
    navigate(`/login?to=${location.pathname}`);
  };

  return (
    <AppBar
      sx={{
        boxShadow: 'none',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: 'white',
      }}>
      <Toolbar >
        <LogoLink sx={{
          height: 30
        }} />
        <Box flexGrow={1} />
        <Button color="inherit" onClick={handleLogin}>ログイン</Button>
      </Toolbar>
    </AppBar>
  )
};

const Header = () => {
  const user = useUser();

  return (
    <>
      {user
        ? <UsersHeader />
        : <LandingHeader />
      }
    </>
  )
}

export default Header;
