import '@fontsource/josefin-sans';
import '@fontsource/roboto';
import 'services/AuthService';
import 'configs';
import { ReactNode, useEffect } from 'react';
import { useAsync, useLocation } from 'react-use';
import { Outlet, useNavigationType } from 'react-router-dom';
import { Box, createTheme, CssBaseline, GlobalStyles, responsiveFontSizes, ThemeProvider, Toolbar } from '@mui/material';
import CompanyService from 'services/CompanyService';
import useTracker from 'hooks/useTracker';
import { useGlobalCompanyList } from 'hooks/useCompany';
import { UserProvider, useUser } from 'components/context/UserProvider';
import AsyncPresenter from 'components/pages/Article/Presenter';
import Header from 'components/ui/Header';

let theme = createTheme({
  palette: {
    primary: {
      main: '#20ABA2',
    },
    secondary: {
      main: '#f50057',
    },
  }
});
theme = responsiveFontSizes(theme);

const globalStyle = <GlobalStyles styles={{
  'html, body, #root': {
    height: '100%',
    width: '100%',
  },
  'body': {
    backgroundColor: theme.palette.grey[50]
  }
}} />;

const useGlobalState = () => {
  const [, setGlobalCompanies] = useGlobalCompanyList();

  const fetchState = useAsync(async () => {
    const companies = await CompanyService.fetchAll();
    setGlobalCompanies(companies);
  }, []);

  return fetchState;
};

const Debug = () => {
  const user = useUser();
  const navigationType = useNavigationType();
  const location = useLocation();

  useEffect(() => {
    console.log(navigationType);
    console.log(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    console.log(user);
  }, [user]);

  return <></>;
};

const AuthPresenter = ({ children }: { children: ReactNode }) => {
  const user = useUser();

  return (
    <AsyncPresenter loading={user === undefined}>
      {children}
    </AsyncPresenter>
  );
};

const AppFrame = () => {
  const fetchState = useGlobalState();
  useTracker([
    { trackingId: process.env.REACT_APP_GA_MEASUREMENT_ID },
    { trackingId: process.env.REACT_APP_GA4_MEASUREMENT_ID }
  ]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {globalStyle}
      <AsyncPresenter loading={fetchState.loading}>
        <UserProvider>
          <Box display="flex" height={1}>
            <Debug />
            <Header />
            <Box component="main" width="1" display="flex" flexDirection="column">
              <Toolbar />
              <AuthPresenter>
                <Outlet />
              </AuthPresenter>
            </Box>
          </Box>
        </UserProvider>
      </AsyncPresenter>
    </ThemeProvider >
  );
};

export default AppFrame;