import React, { useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import * as firebaseAuth from 'firebase/auth';
import UserModel from 'model/User';
import UserService from 'services/UserService';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { ArticleRef } from 'model/IArticle';

export type User = UserModel & {
  isFollowedAt: (stockCode: string | undefined) => boolean,
  setFollowedAt: (stockCode: string | undefined, toAdd?: boolean) => void,
  setBookmarkAt: (ref: ArticleRef, toAdd?: boolean) => void,
  isBookmarkedAt: (ref: ArticleRef) => boolean,
  addVisitedArticle: (ref: ArticleRef) => void;
  isVisitedAt: (ref: ArticleRef) => boolean;
  refresh: (userBaseInfo?: firebaseAuth.UserInfo) => Promise<void>
};

const UserContext = React.createContext<User | null | undefined>(undefined);

type UserProviderProps = {
  children?: React.ReactNode;
};

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<UserModel | null | undefined>();
  const location = useLocation();

  useLayoutEffect(() => {
    const auth = firebaseAuth.getAuth();
    const unsubscribed = auth.onAuthStateChanged((user) => {
      if (!user) {
        setUser(null);
        return;
      }

      (async () => {
        const userInfo = await UserService.fetch(user);
        setUser(userInfo);
      })();
    });
    return () => {
      unsubscribed();
    };
  }, []);

  useEffect(() => {
    // 遷移都度最新の情報を取得する

    if (!user) {
      return;
    }
    (async () => {
      const userInfo = await UserService.fetch(user);
      setUser(userInfo);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const userInfo = useMemo<User | null | undefined>(() => {
    if (!user) {
      return user;
    }

    const isFollowedAt = (stockCode: string | undefined) => {
      const follows = new Set(user.follows);
      return !!stockCode && !!user && follows.has(stockCode);
    };

    const setFollowedAt = (stockCode: string | undefined, toAdd?: boolean) => {
      if (!stockCode || !user) {
        return;
      }
      const follows = new Set(user.follows);

      if (toAdd ?? !follows.has(stockCode)) {
        follows.add(stockCode);
      } else {
        follows.delete(stockCode);
      }

      const newValue = {
        ...user,
        follows: Array.from(follows)
      };

      setUser(newValue);
      UserService.update(newValue);
    };

    const setBookmarkAt = (ref: ArticleRef, toAdd?: boolean) => {
      // FIXME:
      // 現在apiごとに記事IDが割り振られているため暫定的に種別とIDで一意とする

      const bookmarkKeys = new Set(user.bookmarks.map(v => `${v.type}${v.id}`));
      let bookmarks = [...user.bookmarks];

      if (toAdd ?? !bookmarkKeys.has(`${ref.type}${ref.id}`)) {
        bookmarks.push(ref);
      } else {
        bookmarks = bookmarks.filter(v => `${v.type}${v.id}` !== `${ref.type}${ref.id}`)
      }

      const newValue = {
        ...user,
        bookmarks: bookmarks
      };

      setUser(newValue);
      UserService.update(newValue);
    };

    const isBookmarkedAt = (ref: ArticleRef) => {
      return user.bookmarks.some(v => `${v.type}${v.id}` === `${ref.type}${ref.id}`)
    };

    const addVisitedArticle = (ref: ArticleRef) => {

      const newHistory = [...user.visitedHistory];
      newHistory.push({
        ...ref,
        timestamp: dayjs().unix()
      });
      const newValue = {
        ...user,
        visitedHistory: newHistory
      };

      setUser(newValue);
      UserService.update(newValue);
    };

    const isVisitedAt = (ref: ArticleRef) => {
      const keys = user.visitedHistory.map(v => `${v.id}${v.type}`);
      return (new Set(keys)).has(`${ref.id}${ref.type}`);
    };

    const refresh = async (userBaseInfo?: firebaseAuth.UserInfo) => {
      const refreshed = await UserService.fetch(userBaseInfo ?? user);
      setUser(refreshed);
    };

    return {
      ...user,
      isFollowedAt: isFollowedAt,
      setFollowedAt: setFollowedAt,
      setBookmarkAt: setBookmarkAt,
      addVisitedArticle: addVisitedArticle,
      isVisitedAt: isVisitedAt,
      isBookmarkedAt: isBookmarkedAt,
      refresh: refresh
    };
  }, [user]);

  return (
    <UserContext.Provider value={userInfo}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): User | null | undefined => {
  const userControl = useContext(UserContext);
  return userControl;
};