import Company from 'model/Company';
import { useCallback, useMemo } from 'react';
import { createGlobalState } from 'react-use';

export const useGlobalCompanyList = createGlobalState<Company[] | undefined>([]);

const useCompany = () => {
  const [value] = useGlobalCompanyList();

  const getCompany = useCallback((secCode: string) => {
    return value?.find(v => v.secCode === secCode);
  }, [value]);

  const actions = useMemo(() => ({
    get: getCompany
  }), [getCompany])

  return actions;
};

export default useCompany;