import { Chip } from '@mui/material';
import IArticle from 'model/IArticle';

const CategoryChips = ({ value }: { value?: IArticle }) => {
  const articleType = value?.type;
  const sourceType = value?.source.type;

  switch (articleType) {
    case 'securities':
      return <Chip label="IR / 有価証券報告書" size="small" variant='outlined' />;
    case 'result':
      return <Chip label="IR / 決算短信" size="small" variant='outlined' />;
    case 'other-disclose':
      return <Chip label="IR / その他" size="small" variant='outlined' />;
    case 'public':
      if (sourceType === 'nikkei')
        return <Chip label="日経ニュース" size="small" variant='outlined' />;
      else if (sourceType === 'pressrelease')
        return <Chip label="プレスリリース" size="small" variant='outlined' />;
      else
        return <Chip label="その他" size="small" variant='outlined' />;;
    default:
      return <Chip label="その他" size="small" variant='outlined' />;
  }
};

export default CategoryChips;
