import React from 'react';
import { Box, BoxProps, CircularProgress } from '@mui/material';

const ProgressBox = React.forwardRef<any, BoxProps>((props, ref) => (
  <Box
    ref={ref}
    sx={{
      display: 'flex',
      justifyContent: 'center',
      mt: 2,
      ...props.sx,
    }}
  >
    <CircularProgress />
  </Box>
));

export default ProgressBox;