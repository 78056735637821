import axios from 'axios';
import Company from 'model/Company';
import { ArticleSource } from 'model/IArticle';
import IDisclosure from 'model/IDisclosure';
import OtherDisclosure from 'model/Other';
import Securities from 'model/Securities';
import CompanyService from 'services/CompanyService';

const toDisclosure = (value: any) => {
  const secCode = (value['secCode'] || '').slice(0, -1);

  const docId = `y${value['docID']}`;
  const subject = { secCode: secCode };
  const pdfUrl = value['pdfUrl'];
  const source: ArticleSource = {
    type: 'yuuhou',
    company: CompanyService.get(secCode) as Company,
    url: pdfUrl
  };
  const publishedAt = value['submitDateTime'];
  const title = value['docDescription'];
  const content = value['text'];
  const increaseKeywords = value['keywords_increase'] ?? [];
  const decreaseKeywords = value['keywords_decrease'] ?? [];
  const type = value['src_type'];

  if (type === 'yuuhou') {
    return new Securities(
      docId,
      publishedAt,
      `/article/securities/${docId}`,
      subject,
      source,
      title,
      content,
      increaseKeywords,
      decreaseKeywords
    );
  } else {
    return new OtherDisclosure(
      docId,
      publishedAt,
      `/article/other-disclose/${docId}`,
      subject,
      source,
      title,
      pdfUrl
    );
  }
};

/**
 * 有価証券報告書取得
 */
const YuuhouService = {

  fetchAll: async (secCode?: string): Promise<IDisclosure[]> => {
    const response = await axios.get(`${runtime.env.API_URL}/api/yuuhou/list/${secCode}0`);
    return response.data.data.map((v: any) => toDisclosure(v));
  },

  fetch: async (docId: string): Promise<IDisclosure> => {
    const response = await axios.get(`${runtime.env.API_URL}/api/yuuhou/${docId.substring(1)}`);
    return toDisclosure(response.data.data);
  }
};

export default YuuhouService;