import { ArticleSource, ArticleSubject, ArticleTypes } from 'model/IArticle';
import IDisclosure from 'model/IDisclosure';

/**
 * その他記事
 * 有価証券報告書 及び 決算短信報告書 に該当しない適時開示情報
 */
export default class OtherDisclosure implements IDisclosure {
  id;
  type: ArticleTypes = 'other-disclose';
  publishedAt;
  url;
  subject;
  source;
  title;
  content = null;

  pdfUrl?: string;
  // TODO: 以下をsourceに以降
  ref: 'yuuhou' | 'tanshin';

  constructor(
    id: string,
    publishedAt: string,
    url: string,
    subject: ArticleSubject,
    source: ArticleSource,
    title: string,
    pdfUrl: string | undefined,
  ) {
    this.id = id;
    this.publishedAt = publishedAt;
    this.url = url;
    this.subject = subject;
    this.source = source;
    this.title = title;

    this.pdfUrl = pdfUrl;
    this.ref = source.type as ('yuuhou' | 'tanshin');
  }
}
