import React from 'react';
import { Chip, ChipProps, styled } from '@mui/material';

const KeywordChip = React.forwardRef<any, ChipProps>((props, ref) => (
  <Chip
    {...props}
    ref={ref}
    sx={{
      boxShadow: 1
    }}
  />
));

export default KeywordChip;

export const IncreaseWordChip = styled(KeywordChip)<ChipProps>(() => ({
  backgroundColor: '#f44336', // red[500]
  color: 'white',
  '&:hover, &:focus': {
    backgroundColor: '#f44336DE' // red[500] 87%
  }
}));

export const DecreaseWordChip = styled(KeywordChip)<ChipProps>(() => ({
  backgroundColor: '#2196f3', // blue[500]
  color: 'white',
  '&:hover, &:focus': {
    backgroundColor: '#2196f3DE' // blue[500] 87%
  }
}));
