import axios from 'axios'
import Company from 'model/Company';

let cache: Company[] | undefined; 

const CompanyService = {

  fetchAll: async (): Promise<Company[] | undefined> => {
    const response = await axios.get(`/dev/mock/company/companies.json`);
    cache = response.data.map((v: any) => new Company(
      v['code'],
      v['company_info']?.['正式社名'],
      v['company_info']?.['英文社名'],
      v['company_info']?.['相場欄名'],
      v['company_info']?.['thumbnailUrl']
    ));

    return cache;
  },

  get: (secCode: string | undefined): Company | undefined => {
    if (!secCode) {
      return;
    }

    if (!cache) {
      CompanyService.fetchAll();
    }

    return cache?.find(v => v.secCode === secCode)
  }
};

export default CompanyService;