import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { Box, Divider, Paper, Stack, ToggleButton, Typography } from '@mui/material';
import TimeLineService from 'services/TimeLineService';
import useCompany from 'hooks/useCompany';
import { useUser } from 'components/context/UserProvider';
import CompanyIcon from 'components/ui/CompanyIcon';
import Container from 'components/ui/Container';
import TimeLineCard from 'components/containers/TimeLineCard';
import ProgressBox from 'components/ui/ProgressBox';

const Company = () => {
  const user = useUser();
  const { secCode = '' } = useParams();
  const { get: getCompany } = useCompany();

  const company = useMemo(() => {
    return getCompany(secCode);
  }, [getCompany, secCode]);

  const fetchTimelines = useAsync(async () => {
    try {
      const res = await TimeLineService.fetchAll([secCode]);

      let timeLine = res.map(v => ({
        ...v,
        isVisited: user?.isVisitedAt(v),
        isBookmarked: user?.isBookmarkedAt(v)
      }));

      return timeLine;
    } catch (e) {
      console.log(e);
    }
    return [];
  }, []);

  const handleOnClick = () => {
    user?.setFollowedAt(secCode);
  };

  const renderTimeLine = () => {
    const timeLineItems = fetchTimelines.value ?? [];

    return (
      <Stack spacing={1}>
        {timeLineItems.map((v, i) => <TimeLineCard key={i} value={v} hideAvatar />)}
      </Stack>
    );
  };

  return (
    <Container maxWidth="md" >
      <Paper sx={{ p: 2 }} square>
        <Stack direction="row">
          <Box p={1}>
            <CompanyIcon
              alt={company?.name}
              src={company?.thumbnailUrl}
              sx={{
                width: 120,
                height: 120
              }}
            />
          </Box>
          <Box p={1} pt={3} flexGrow={1}>
            <Typography variant="h5" component="h1" sx={{ fontWeight: 'bold' }}>{company?.name}</Typography>
            <Typography variant="h6" component="h2" >{company?.secCode}</Typography>
            {
              user
                ? <ToggleButton
                  value=""
                  selected={user.isFollowedAt(company?.secCode)}
                  fullWidth
                  sx={{
                    mt: 2,
                    maxWidth: 300,
                    height: 36,
                  }}
                  onClick={handleOnClick}
                >
                  {user.isFollowedAt(company?.secCode) ? 'フォロー中' : 'フォローする'}
                </ToggleButton>
                : null
            }
          </Box>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ m: '0 auto', mt: 2 }}>
          {fetchTimelines.loading
            ? <ProgressBox />
            : renderTimeLine()
          }
        </Box>
      </Paper>
    </Container>
  );
};

export default Company;