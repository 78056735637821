import axios from 'axios';
import FinancialResult, { FinancialResultItem, ja_en as FinancialResultItemLabel } from 'model/FinancialResult';
import IDisclosure from 'model/IDisclosure';
import OtherDisclosure from 'model/Other';
import Company from 'model/Company';
import { ArticleSource } from 'model/IArticle';
import CompanyService from 'services/CompanyService';

const toDisclosure = (value: any) => {
  const secCode = (value['code'] || '').slice(0, -1);

  const docId = `t${String(value['_id'])}`;
  const subject = { secCode: secCode };
  const pdfUrl = value['pdfUrl'];
  const source: ArticleSource = {
    type: 'tanshin',
    company: CompanyService.get(secCode) as Company,
    url: pdfUrl
  };
  const publishedAt = value['submitDateTime'];
  const title = value['title'];
  const resultDataStr = value['data'];
  const type = value['src_type'];

  if (JSON.tryParse(resultDataStr)) {
    const resultData = JSON.parse(resultDataStr);
    const resultItem: FinancialResultItem = {};
    Object.keys(resultData).forEach(resultItemKey => {
      const item: Record<string, number> = {};
      Object.keys(resultData[resultItemKey]).forEach(financialItemKey => {
        item[FinancialResultItemLabel[financialItemKey]] = Number(resultData[resultItemKey][financialItemKey].replace(/,/g, ''));
      });
      resultItem[FinancialResultItemLabel[resultItemKey]] = item;
    });

    return new FinancialResult(
      docId,
      publishedAt,
      `/article/result/${docId}`,
      subject,
      source,
      title,
      resultItem
    );
  } else if (type === 'tanshin') {
    return new FinancialResult(
      docId,
      publishedAt,
      `/article/result/${docId}`,
      subject,
      source,
      title
    );
  } else {
    return new OtherDisclosure(
      docId,
      publishedAt,
      `/article/other-disclose/${docId}`,
      subject,
      source,
      title,
      pdfUrl
    );
  }
};

/**
 * 決算短信取得
 */
const TanshinService = {

  fetchAll: async (secCode?: string): Promise<IDisclosure[]> => {
    const response = await axios.get(`${runtime.env.API_URL}/api/tanshin/list/${secCode}0`);
    return response.data.data.map((v: any) => toDisclosure(v));
  },

  fetch: async (docId: string): Promise<IDisclosure> => {
    const response = await axios.get(`${runtime.env.API_URL}/api/tanshin/${docId.substring(1)}`);
    return toDisclosure(response.data.data);
  }
};

export default TanshinService;