import { styled } from '@mui/material';
import Link, { LinkProps } from 'components/ui/Link';
import React from 'react';

const StyledLink = styled(Link)<LinkProps>(() => ({
  width: 160,
  height: 80,
  backgroundImage: `url(${process.env.PUBLIC_URL + '/logo.svg'})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat'
}));

const LogoLink = React.forwardRef<any, Omit<LinkProps, 'to'>>(
  (props, ref) => (
    <StyledLink
      {...props}
      ref={ref}
      to="/"
    />
  )
);

export default LogoLink;