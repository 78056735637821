import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncRetry } from 'react-use';
import Mark from 'mark.js';
import { CloseRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded, SearchRounded } from '@mui/icons-material';
import { Box, Button, Divider, GlobalStyles, IconButton, InputBase, Paper, PaperProps, Stack, Typography, useScrollTrigger } from '@mui/material';
import { getRelativeTime } from 'utils/Utils';
import Securities from 'model/Securities';
import YuuhouService from 'services/YuuhouService';
import AsyncPresenter from 'components/pages/Article/Presenter';
import CategoryChips from 'components/ui/CategoryChips';
import CompanyIcon from 'components/ui/CompanyIcon';
import { IncreaseWordChip } from 'components/ui/KeywordChip';
import Link from 'components/ui/Link';
import { DecreaseWordChip } from 'components/ui/KeywordChip';

const globalStyle = <GlobalStyles styles={{
  'mark.current': {
    backgroundColor: '#fbc02d' //yellow[700]
  }
}} />;

type InnerSearchBarProps = Omit<PaperProps, 'onChange'> & {
  value?: string;
  hitNum?: {
    numer: number;
    denom: number;
  };
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  onClickNext?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPrev?: React.MouseEventHandler<HTMLButtonElement>;
  onClickClear?: React.MouseEventHandler<HTMLButtonElement>;
};

const InnerSearchBar = (props: InnerSearchBarProps) => {
  const { sx, value, hitNum = { numer: 0, denom: 0 }, onChange, onClickNext, onClickPrev, onClickClear } = props;

  return (
    <Paper
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 5,
        width: 1,
        p: '2px 4px'
      }}
    >
      <Box sx={{ p: 1, display: 'inline-flex', alignItems: 'center', color: 'action.active' }}>
        <SearchRounded />
      </Box>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="キーワード検索..."
        onChange={onChange}
        value={value}
      />
      <Box sx={{ pr: 1 }}>
        <Typography fontSize={14} color={(theme) => theme.palette.text.secondary}>{hitNum.numer} / {hitNum.denom}</Typography>
      </Box>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton sx={{ p: '10px' }} onClick={onClickPrev}>
        <KeyboardArrowUpRounded />
      </IconButton>
      <IconButton sx={{ p: '10px' }} onClick={onClickNext} >
        <KeyboardArrowDownRounded />
      </IconButton>
      <IconButton sx={{ p: '10px' }} onClick={onClickClear} >
        <CloseRounded />
      </IconButton>
    </Paper>
  );
};

type ContentProps = {
  value: Securities;
};

const Content = ({ value }: ContentProps) => {
  console.log(value);

  const {
    title,
    source,
    publishedAt,
    increaseKeywords,
    decreaseKeywords,
    content
  } = value;

  const navigate = useNavigate();
  const location = useLocation();

  const contentRef = useRef<HTMLElement>(null);

  const [searchValue, setSearchValue] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [marks, setMarks] = useState<NodeListOf<HTMLElement>>();

  const trigger = useScrollTrigger({ target: window });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setSearchValue(searchParams.get('keyword') || '');
  }, [location]);

  useEffect(() => {
    if (!marks) {
      return;
    }

    const markList = Array.from(marks);
    markList.forEach(v => {
      v.classList.remove('current');
    });

    const currentMark = marks[currentIndex];
    if (!currentMark) {
      return;
    }

    currentMark.classList.add('current');
    currentMark.scrollIntoView({ block: 'center' });
  }, [marks, currentIndex]);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    const mark = new Mark(contentRef.current);
    mark.unmark({
      done: () => {
        mark.mark(searchValue, {
          done: () => {
            setMarks(contentRef.current?.querySelectorAll('mark'));
            setCurrentIndex(0);
          }
        });
      }
    });

  }, [searchValue])


  const clearMark: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!contentRef.current) {
      return;
    }
    setSearchValue('');

    const mark = new Mark(contentRef.current);
    mark.unmark({
      done: () => {
        setMarks(contentRef.current?.querySelectorAll('mark'));
        setCurrentIndex(0);
      }
    });
  }

  const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
    setSearchValue(e.currentTarget.value);
  };

  return (
    <Paper sx={{ p: 2 }} square>
      {globalStyle}

      <Stack direction="row">
        <Box p={1}>
          <CompanyIcon
            alt={source.company.name}
            src={source.company.thumbnailUrl?.toString()}
            sx={{
              width: 48,
              height: 48
            }}
          />
        </Box>
        <Box p={1} flexGrow={1}>
          <Stack direction="row">
            <CategoryChips value={value} />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2} width="1">
              <span>{getRelativeTime(publishedAt, 'LL')}</span>
            </Stack>
          </Stack>
          <Link to={`/company/${source.company.secCode}`} underline="hover" onClick={e => e.stopPropagation()}>
            {source.company.name}
          </Link>
        </Box>
      </Stack>

      <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold' }}>{title}</Typography>
      {increaseKeywords !== undefined
        ? <>
          <Typography variant="subtitle1" color="red" >新出キーワード</Typography>
          <Stack direction="row" flexWrap="wrap" width="1" >
            {
              increaseKeywords.map((v, i) => (
                <Box key={i} sx={{ pb: 1, pr: 1 }}>
                  <IncreaseWordChip
                    label={v}
                    size="small"
                    onClick={() => navigate({
                      search: `?keyword=${v}`
                    }, {
                      replace: true
                    })} />
                </Box>
              ))
            }
          </Stack>
        </>
        : null}
      {decreaseKeywords !== undefined
        ? <>
          <Typography variant="subtitle1" color="blue" >減少キーワード</Typography>
          <Stack direction="row" flexWrap="wrap" width="1" >
            {
              decreaseKeywords.map((v, i) => (
                <Box key={i} sx={{ pb: 1, pr: 1 }}>
                  <DecreaseWordChip
                    label={v}
                    size="small"
                    onClick={() => navigate({
                      search: `?keyword=${v}`
                    }, {
                      replace: true
                    })} />
                </Box>
              ))
            }
          </Stack>
        </>
        : null}

      <Divider />
      <Box pt={2} />
      <InnerSearchBar
        value={searchValue}
        hitNum={marks && { numer: marks.length === 0 ? 0 : currentIndex + 1, denom: marks.length }}
        sx={{
          position: 'sticky',
          top: trigger ? 10 : 70
        }}
        onChange={handleOnChange}
        // TODO 外出し
        onClickNext={() => setCurrentIndex((prev) => prev + 1 < (marks?.length ?? 0) ? ++prev : 0)}
        onClickPrev={() => setCurrentIndex((prev) => prev - 1 < 0 ? (marks?.length ?? 0) : --prev)}
        onClickClear={clearMark}
      />
      <Box ref={contentRef} mt={2}>
        {content.split(/\r\n|\n/).map((v, i) =>
          <Typography key={i} variant="body1" sx={{ wordWrap: 'break-word' }}>{v}</Typography>
        )}
      </Box>
      <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1.5
          }}
        >
          <Button variant="outlined" href={source.url || ''} target="_blank" >PDFを開く</Button>
        </Box>
    </Paper>
  );
};


const SecuritiesArticle = ({ docId }: { docId: string }) => {

  const fetchState = useAsyncRetry(() => YuuhouService.fetch(docId), []);

  return (
    <AsyncPresenter loading={fetchState.loading}>
      {fetchState.value
        ? <Content value={fetchState.value as Securities} />
        : null}
    </AsyncPresenter>
  );
};

export default SecuritiesArticle;