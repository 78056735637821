import React from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { LinkProps as RouterLinkProps, useHref, useLinkClickHandler } from 'react-router-dom';

type LinkPropsBase = RouterLinkProps & MuiLinkProps & {
  component?: any;
};

export type LinkProps = Omit<LinkPropsBase, 'to'> & {
  to?: string;
};

const RouterLink = React.forwardRef<any, LinkPropsBase>((props, ref) => {
  const {
    component,
    to,
    replace = false,
    state,
    target,
    sx,
    onClick,
    ...rest } = props;

  const href = useHref(to);
  const innerClick = useLinkClickHandler(to, {
    replace,
    state,
    target
  });

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    onClick?.(e);

    if (!e.defaultPrevented) {
      innerClick(e);
    }
  };

  return (
    <MuiLink
      {...rest}
      ref={ref}
      href={href}
      target={target}
      sx={{
        wordBreak: 'break-all',
        ...sx
      }}
      onClick={handleClick}
      component={component}
    />
  );
});

const Link = React.forwardRef<any, Omit<LinkProps, 'to'> & { to?: string }>((props, ref) => {
  return (
    <>
      {props.to
        ? <RouterLink {...props} ref={ref} to={props.to} />
        : <MuiLink
            {...props}
            ref={ref}
            sx={{
              wordBreak: 'break-all',
              ...props.sx
            }}
          />
      }
    </>
  );
});

export default Link;