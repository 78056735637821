import { Slide, useScrollTrigger } from '@mui/material';
import React from 'react';

type HideOnScrollProps = {
  children: React.ReactElement;
};

const HideOnScroll = (props: HideOnScrollProps) => {
  const { children } = props;

  // 下方向 true, 上方向 false
  const trigger = useScrollTrigger({
    target: window
  });

  // 最上端 true
  const triggerOnTop = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {React.cloneElement(children, {
        elevation: triggerOnTop ? 4 : 0,
      })}
    </Slide>
  );
};

export default HideOnScroll;