import { FocusEventHandler, FormEventHandler, ReactNode, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider } from "firebase/auth";
import validator from 'validator';
import { Google as GoogleIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Divider, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { FirebaseError } from 'firebase/app';
import * as firebaseAuth from "firebase/auth";

const SingupForm = ({ headerLabel }: { headerLabel?: ReactNode }) => {

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isPasswordEmpty, setIsPasswordEmpty] = useState<boolean>(true);
  const canSubmit = useMemo<boolean>(() => {
    return !isEmailError && !isPasswordEmpty;
  }, [isEmailError, isPasswordEmpty]);

  const navigate = useNavigate();

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    if (!email || !password) {
      return;
    }

    (async () => {

      try {
        await firebaseAuth.createUserWithEmailAndPassword(
          firebaseAuth.getAuth(),
          email.toString(),
          password.toString()
          );
          
          navigate('/');
        } catch (e) {
        // TODO: 共通化
        let message = '登録に失敗しました';
        if (e instanceof FirebaseError) {
          switch (e.code) {
            case 'auth/email-already-in-use':
              message = 'そのメールアドレスはすでに登録されています。';
              break;
            case 'auth/invalid-password':
              message = 'スワードは 6 文字以上で設定してください';
              break;
          }
        }

        console.log(e);
        window.alert(message);
      }
    })();
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = firebaseAuth.getAuth();
    (async () => {
      try {
        await firebaseAuth.signInWithRedirect(auth, provider);
      } catch (e) {
        console.log(e);
      }
    })();
  };

  const handleEmailBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.value;
    if (!value) {
      return;
    }
    setIsEmailError(!validator.isEmail(value));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
      }}
    >
      {/* <Typography component="h1" variant="h5">
        新規登録
      </Typography> */}
      <Box
        component="form"
        sx={{
          width: '100%'
        }}
        onSubmit={handleSubmit}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="メールアドレス"
          name="email"
          autoComplete="email"
          autoFocus
          onBlur={handleEmailBlur}
          error={isEmailError}
          helperText={isEmailError && '有効なメールアドレスを入力してください。'}
        />
        <Box mt={2} />
        <FormControl variant="outlined" required fullWidth>
          <InputLabel htmlFor="password" required>パスワード</InputLabel>
          <OutlinedInput
            id="password"
            name='password'
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            required
            label="パスワード 　"
            fullWidth
            onChange={(e) => setIsPasswordEmpty(!e.currentTarget.value)}
          />
        </FormControl>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={!canSubmit}
        >
          登録
        </Button>
        <Divider>または</Divider>
        <Box mt={2} />

        <Button
          fullWidth
          variant="outlined"
          startIcon={
            <GoogleIcon />
          }
          sx={{
            color: (theme) => theme.palette.text.primary
          }}
          onClick={handleClickGoogle}
        >
          Google で登録
        </Button>

      </Box>
    </Box >
  );
};

export default SingupForm;