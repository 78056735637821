import { ReactNode } from 'react';
import { Box, CircularProgress } from '@mui/material';

const AsyncPresenter = ({ loading, children }: { loading: boolean, children?: ReactNode }) => {

  return (
    <>
      {loading
        ? <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}><CircularProgress /></Box>
        : children
      }
    </>
  );
};

export default AsyncPresenter;