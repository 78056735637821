import { useCallback, useState, useMemo } from 'react';
import { TimeLineCardProps } from 'components/ui/TimeLineCard';
import { useUser } from 'components/context/UserProvider';
import TimeLineCardBase, { TimeLineCardValue as TimeLineCardValueBase } from 'components/ui/TimeLineCard';

export type TimeLineCardValue = Omit<TimeLineCardValueBase, 'isVisited' | 'isBookmarked'>;

const TimeLineCard = (props: TimeLineCardProps) => {
  const { value, onClick, onToggleBookmark, ...rest } = props;
  const user = useUser();

  const [isVisited, setIsVisited] = useState(() => user?.isVisitedAt(value));
  const [isBookmarked, setIsBookmarked] = useState(() => user?.isBookmarkedAt(value));

  const item = useMemo(() => {
    return {
      ...value,
      isBookmarked: isBookmarked,
      isVisited: isVisited
    };
  }, [value, isBookmarked, isVisited])

  const handleOnClickTimeLine = useCallback<React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLSpanElement>>((e) => {
    user?.addVisitedArticle(value);
    setIsVisited(true);
    onClick?.(e);
  }, [value, user, onClick]);

  const handleOnBookmark = useCallback<React.MouseEventHandler<HTMLButtonElement>>((e) => {
    user?.setBookmarkAt(value);
    setIsBookmarked((prev) => !prev);
    onToggleBookmark?.(e);
  }, [value, user, onToggleBookmark]);

  return (
    <TimeLineCardBase
      {...rest}
      value={item}
      onClick={handleOnClickTimeLine}
      onToggleBookmark={handleOnBookmark}
    />
  );
};

export default TimeLineCard;